import React from 'react';
import ReactDOM from 'react-dom';
import Login from './modulos/Login/Login';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.StrictMode>
    <Login />
  </React.StrictMode>,
  document.getElementById('root')
);
// Habilitar para PWA (Service Workers): https://bit.ly/CRA-PWA
serviceWorker.register();
