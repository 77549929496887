import React, {Component} from 'react';

import whatsapp from '../assets/imagens/icones/whatsapp_fundo_claro.png';
import tv from '../assets/imagens/icones/teamviewer.png';
import yt from '../assets/imagens/icones/youtube.png';
import qr from '../assets/imagens/icones/qrcode.png';

export default class Suporte extends Component {
  render() {
    return (
      <div style={{marginTop: '20px'}}>
        <img alt="WhatsApp" src={whatsapp} style={{width: '50px', height: '50px', marginRight: '15px'}}/>
        <img alt="TeamViewer" src={tv} style={{width: '50px', height: '50px', marginRight: '15px'}}/>
        <img alt="Youtube" src={yt} style={{width: '50px', height: '50px', marginRight: '15px'}}/>
        <img alt="qrCode" src={qr} style={{width: '50px', height: '50px', marginRight: '15px'}}/>
      </div>
    );
  }
}
