import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap';
import Suporte from '../../componentes/Suporte';
//Visual
import './login.css';
import Logo from '../../assets/imagens/axerweb_logo-fundo-claro.png';

export default class Login extends Component {
  render() {
    return (
      <div style={{
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent:'center', 
          alignItems:'center', 
          marginTop:'50px'
      }}>
        <Form className='formLogin'>
          <img 
            src={Logo}
            alt="AxerWeb Login"
            style={{
              paddingBottom: 30,
              width: '320px',
              height: 'auto'
            }}
          />
          <Form.Group controlId="formBasicEmail">
            <Form.Control type="email" placeholder="Digite seu email" style={{borderRadius: 10}}/>
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Control type="password" placeholder="Sua senha" style={{borderRadius: 10}}/>
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Lembrar meu login" />
          </Form.Group>
          <Button 
            variant="primary" 
            type="submit" 
            className="button" 
            style={{
              backgroundColor: '#4C9550',
              fontWeight: 'bolder',
              border: 0
            }}
          >
            Entrar »
          </Button>
        </Form>
        <Suporte />
      </div>
    );
  }
}
